<template>
  <div class="app-container"
       v-loading.body="isLoading"
       element-loading-background="rgba(255, 255, 255, 1)"
  >
    <el-card>
      <h2 class="heading page-heading">{{ $t('page_admin_users.title') }}</h2>
      <el-table
          @sort-change="customSort"
          :data="usersToShow"
          style="width: 100%"
      >
        <el-table-column
            prop="name"
            sortable="custom"
            :label="$t('page_admin_users.table_headers.name')"
        />
        <el-table-column
            prop="surname"
            sortable
            :label="$t('page_admin_users.table_headers.surname')"
        />
        <el-table-column
            prop="email"
            sortable
            :label="$t('page_admin_users.table_headers.email')"
        />
        <el-table-column
            :width="130"
            prop="paymentSum"
            sortable="custom"
            :label="$t('page_admin_users.table_headers.paymentSum')"
        >
          <template #default="{row}">
            <div class="text-right">
              {{ $options.moneyFormat(row.paymentSum) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="roleName"
            sortable
            :label="$t('page_admin_users.table_headers.role')"
        />
        <el-table-column
            prop="status"
            sortable
            :label="$t('page_admin_users.table_headers.status')"
        >
          <template #default="{row}">
            <el-tag :type="applyTagType(row.status)">
              {{ $t(`page_admin_users.user_statuses.${row.status}`) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
            prop="status"
            sortable
            :label="$t('page_admin_users.table_headers.actions')"
        >
          <template #default="{row}">
            <el-dropdown placement="bottom" @command="handleCommand">
              <span class="el-dropdown-link">
                <v-icon name="more_horiz"></v-icon>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="{commandName: $options.CHANGE_ROLE_COMMAND, params: {userID: row.userId, roleID: row.roleId}}">
                  {{ $t('page_admin_users.actions_dropdown.action_change_role') }}
                </el-dropdown-item>
                <el-dropdown-item v-if="row.status !== $options.USER_STATUS_NOT_ACTIVE"
                    :command="{commandName: $options.CHANGE_STATUS_COMMAND, params: {userID: row.userId, currentStatus: row.status}}">
                  {{ $t('page_admin_users.actions_dropdown.action_change_status') }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <v-pagination :items="userList" @update:items-to-show="usersToShow = $event"></v-pagination>
      <ChangeRoleDialog @role-changed="fetchUserList" ref="changeRoleDialog" />
      <ChangeStatusDialog @status-changed="fetchUserList" ref="changeStatusDialog" />
    </el-card>
  </div>
</template>

<script>
import {
  fetchAdminUserList,
} from '../shared/services/user-service'
import {moneyFormat} from '../../../shared/utils/moneyFormat'
import {
  USER_STATUS_ACTIVE,
  USER_STATUS_BLOCKED,
  USER_STATUS_NOT_ACTIVE
} from '../../../shared/constants/user-statuses'

import VIcon from '../../../components/ui/v-icon/VIcon'
import VPagination from '../../../components/ui/v-pagination/VPagination'

import ChangeRoleDialog from '../components/change-role-dialog/ChangeRoleDialog'
import ChangeStatusDialog from '../components/change-status-dialog/ChangeStatusDialog'

import {tableSort} from '../../../shared/utils/tableSort'

const CHANGE_ROLE_COMMAND = 'changeUserRole'
const CHANGE_STATUS_COMMAND = 'changeUserStatus'

export default {
  name: 'UserList',
  components: {
    ChangeStatusDialog,
    VPagination,
    ChangeRoleDialog,
    VIcon
  },
  USER_STATUS_NOT_ACTIVE,
  CHANGE_ROLE_COMMAND,
  CHANGE_STATUS_COMMAND,
  moneyFormat,
  data() {
    return {
      userList: [],
      usersToShow: [],
      roleList: [],
      isLoading: true
    }
  },
  created() {
    this.fetchUserList()
  },
  methods: {
    handleCommand({commandName, params}) {
      this[commandName](params)
    },
    [CHANGE_ROLE_COMMAND]({userID, roleID}) {
      this.$refs.changeRoleDialog.open(userID, roleID)
    },
    [CHANGE_STATUS_COMMAND]({userID, currentStatus}) {
      this.$refs.changeStatusDialog.open(userID, currentStatus)
    },
    customSort(sortInfo) {
      this.userList = tableSort(this.userList, sortInfo)
    },
    fetchUserList() {
      this.isLoading = true
      fetchAdminUserList()
          .then((res) => {
            this.userList = res
            this.isLoading = false
          })
          .catch((e) => {
            this.$message({
              type: 'error',
              message: `${this.$t(`${e}`)}`
            })
          })
    },
    applyTagType(status) {
      const tagTypes = {
        [USER_STATUS_BLOCKED]: 'danger',
        [USER_STATUS_NOT_ACTIVE]: 'warning',
        [USER_STATUS_ACTIVE]: 'primary',
      }
      return tagTypes[status]
    }
  }
}
</script>
