<template>
  <v-dialog ref="dialog" :title="$t('page_admin_users.change_status_dialog.title')">
    <template #body>
      <el-form label-position="top"
               size="medium"
               ref="form"
      >
        <el-form-item :label="$t('page_admin_users.change_status_dialog.select_label')">
          <el-select v-model="isBlocked">
            <el-option :value="true" :label="$t('page_admin_users.change_status_dialog.select_option_block')"/>
            <el-option :value="false" :label="$t('page_admin_users.change_status_dialog.select_option_active')"/>
          </el-select>
        </el-form-item>
      </el-form>
    </template>
  </v-dialog>
</template>

<script>
import VDialog from '../../../../components/ui/v-dialog/VDialog'

import {blockUser, unblockUser} from '../../shared/services/user-service'

import {
  USER_STATUS_BLOCKED,
  USER_STATUS_ACTIVE
} from '../../../../shared/constants/user-statuses'

export default {
  name: 'ChangeStatusDialog',
  components: {
    VDialog
  },
  data() {
    return {
      isBlocked: false
    }
  },
  methods: {
    setUserCurrentStatus(status) {
      const statuses = {
        [USER_STATUS_ACTIVE]: false,
        [USER_STATUS_BLOCKED]: true
      }

      this.isBlocked = statuses[status]
    },
    reopenModal(userID, currentStatus) {
      this.$message({
        type: 'error',
        message: `${this.$t('page_admin_users.change_status_dialog.messages.change_error')}`
      })
      this.open(userID, currentStatus)
    },
    confirmSuccess() {
      this.$message({
        type: 'success',
        message: `${this.$t('page_admin_users.change_status_dialog.messages.change_success')}`
      })
      this.$emit('status-changed')
    },
    async open(userID, currentStatus) {
      this.setUserCurrentStatus(currentStatus)

      await this.$refs.dialog.openDialog()

      if (this.isBlocked) {
        blockUser(userID)
            .then(() => {
              this.confirmSuccess()
            })
            .catch(() => {
              this.reopenModal(userID, currentStatus)
            })
      } else {
        unblockUser(userID)
            .then(() => {
              this.confirmSuccess()
            })
            .catch(() => {
              this.reopenModal(userID, currentStatus)
            })
      }
    },
  }
}
</script>

<style scoped>

</style>
