<template>
  <v-dialog ref="dialog" :title="$t('page_admin_users.change_role_dialog.title')">
    <template #body>
      <el-form label-position="top"
               size="medium"
               ref="form"
      >
        <el-form-item :label="$t('page_admin_users.change_role_dialog.select_label')">
          <el-select v-model="selectedRole">
            <el-option v-for="role in roleOptions"
                       :key="role.roleId"
                       :value="role.roleId"
                       :label="role.name"
            />
          </el-select>
        </el-form-item>
      </el-form>
    </template>
  </v-dialog>
</template>

<script>
import VDialog from '../../../../components/ui/v-dialog/VDialog'
import {fetchRoleList, updateUserRole} from '../../shared/services/user-service'

export default {
  name: 'ChangeRoleDialog',
  components: {VDialog},
  data() {
    return {
      selectedRole: null,
      roleOptions: []
    }
  },
  created() {
    this.fetchRoles()
  },
  methods: {
    async open(userID, currentRole) {
      this.selectedRole = currentRole
      const dialogResponse = await this.$refs.dialog.openDialog()
      if (dialogResponse) {
        updateUserRole(userID, this.selectedRole)
          .then(() => {
            this.$message({
              type: 'success',
              message: `${this.$t('page_admin_users.change_role_dialog.messages.change_success')}`
            })
            this.$emit('role-changed')
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: `${this.$t('page_admin_users.change_role_dialog.messages.change_error')}`
            })
            this.open(userID, currentRole)
          })
      }
    },
    fetchRoles() {
      this.isLoading = true
      fetchRoleList()
          .then((res) => {
            this.roleOptions = res
            this.isLoading = false
          })
          .catch((e) => {
            this.$message({
              type: 'error',
              message: `${this.$t(`${e}`)}`
            })
          })
    },
  }
}
</script>

<style scoped>

</style>
