import {api} from '../../../../shared/services/backend-api'

const RESOURCE = 'users'

const userModel = (user) => {
  
    const {
      userId,
      name,
      surname,
      email,
      roleName,
      roleId,
      paymentSum,
      status
    } = user
  
  return {
      userId,
      name,
      surname,
      email,
      roleName,
      roleId,
      paymentSum,
      status
  }
}

const fetchAdminUserList = async () => {
  const { data } = await api.get(`${RESOURCE}`)
  const userList = data.data.map((item) => userModel(item))
  
  return userList
}

const fetchRoleList = async () => {
  const { data } = await api.get(`roles`)
  
  return data
}

const updateUserRole = (userID, roleID) => {
  return api.put(`${RESOURCE}/${userID}/changeRole/${roleID}`)
}

const blockUser = (userID) => {
  return api.put(`${RESOURCE}/${userID}/block`)
}

const unblockUser = (userID) => {
  return api.put(`${RESOURCE}/${userID}/unblock`)
}

export {
  fetchAdminUserList,
  fetchRoleList,
  updateUserRole,
  blockUser,
  unblockUser
}
